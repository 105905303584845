import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode, CandlestickSeries } from 'lightweight-charts';

const MinuteChart = ({ historyData, priceData, interval = 30 }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const lastCandleRef = useRef(null);

  // Initialize the chart on mount
  useEffect(() => {
    if (!chartContainerRef.current) return;

    if (!chartRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width: 800,
        height: 400,
        crosshair: { mode: CrosshairMode.Normal },
        timeScale: { timeVisible: true, secondsVisible: interval < 60 },
      });

      const candlestickSeries = chart.addSeries(CandlestickSeries, {
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderUpColor: '#26a69a',
        borderDownColor: '#ef5350',
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
      });

      chartRef.current = chart;
      seriesRef.current = candlestickSeries;
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
        seriesRef.current = null;
      }
    };
  }, [interval]);

  // When historical data arrives, initialize the chart with it.
  useEffect(() => {
    if (historyData && historyData.length > 0 && seriesRef.current) {
      seriesRef.current.setData(historyData);
      lastCandleRef.current = historyData[historyData.length - 1];
      chartRef.current.timeScale().fitContent();
      console.log(`Initialized chart with ${historyData.length} historical candles`);
    }
  }, [historyData]);

  // For every new tick, update the chart continuously.
  useEffect(() => {
    if (priceData && priceData.price && priceData.timestamp) {
      handleNewTickData(priceData, interval);
    }
  }, [priceData, interval]);

  const handleNewTickData = (newPriceData, interval) => {
    const { price, timestamp } = newPriceData;
    // If the timestamp appears to be in milliseconds, convert to seconds.
    const ts = timestamp > 1e10 ? Math.floor(timestamp / 1000) : timestamp;
    // Calculate the candle's starting time by rounding down to the nearest interval (in seconds)
    const time = Math.floor(ts / interval) * interval;

    if (!lastCandleRef.current || time > lastCandleRef.current.time) {
      // If no candle exists or we're in a new bucket, update the previous candle if present,
      // then create a new candle.
      if (lastCandleRef.current) {
        seriesRef.current.update(lastCandleRef.current);
      }
      lastCandleRef.current = {
        time,
        open: price,
        high: price,
        low: price,
        close: price,
      };
    } else {
      // Otherwise, update the current candle continuously.
      lastCandleRef.current.high = Math.max(lastCandleRef.current.high, price);
      lastCandleRef.current.low = Math.min(lastCandleRef.current.low, price);
      lastCandleRef.current.close = price;
    }
    if (seriesRef.current) {
      seriesRef.current.update(lastCandleRef.current);
    }
  };

  return <div ref={chartContainerRef} style={{ width: '800px', height: '400px' }} />;
};

export default MinuteChart;








// import React, { useEffect, useRef, useState } from 'react';
// import { createChart, CrosshairMode ,CandlestickSeries} from 'lightweight-charts';

// const MinuteChart = ({ priceData, interval = 1 }) => {
//   const chartContainerRef = useRef(null);
//   const chartRef = useRef(null);
//   const seriesRef = useRef(null);
//   const tickBuffer = useRef([]);
//   const lastCandleRef = useRef(null);
  
//   useEffect(() => {
//     if (!chartRef.current) {
//       const chart = createChart(chartContainerRef.current, {
//         width: 800,
//         height: 400,
//         crosshair: { mode: CrosshairMode.Normal },
//         timeScale: { timeVisible: true, secondsVisible: false },
//       });

//       const candlestickSeries = chart.addSeries(CandlestickSeries,{
//         upColor: '#26a69a',
//         downColor: '#ef5350',
//         borderUpColor: '#26a69a',
//         borderDownColor: '#ef5350',
//         wickUpColor: '#26a69a',
//         wickDownColor: '#ef5350',
//         priceScaleId: "left",
//       });


//       chartRef.current = chart;
//       seriesRef.current = candlestickSeries;

//       // Initialize with some data
//       const initialData = createInitialMinuteData(interval);
//       candlestickSeries.setData(initialData);
//       lastCandleRef.current = initialData[initialData.length - 1];
//     }

//     return () => {
//       if (chartRef.current) {
//         chartRef.current.remove();
//         chartRef.current = null;
//         seriesRef.current = null;
//       }
//     };
//   }, [interval]);

//   useEffect(() => {
//     if (priceData && priceData.price && priceData.timestamp) {
//       handleNewTickData(priceData, interval);
//     }
//   }, [priceData]);

//   const handleNewTickData = (newPriceData, interval) => {
//     const { price, timestamp } = newPriceData;
//     const time = Math.floor(timestamp / (interval * 60)) * (interval * 60); // Round to nearest interval

//     if (!lastCandleRef.current || time > lastCandleRef.current.time) {
//       // Close previous candle and start a new one
//       if (lastCandleRef.current) {
//         seriesRef.current.update(lastCandleRef.current);
//       }

//       lastCandleRef.current = {
//         time,
//         open: price,
//         high: price,
//         low: price,
//         close: price,
//       };
//     } else {
//       // Update the last candle in real-time
//       lastCandleRef.current.high = Math.max(lastCandleRef.current.high, price);
//       lastCandleRef.current.low = Math.min(lastCandleRef.current.low, price);
//       lastCandleRef.current.close = price;
//     }

//     // Animate price tick updates
//     if (seriesRef.current) {
//       seriesRef.current.update(lastCandleRef.current);
//     }
//   };

//   return <div ref={chartContainerRef} style={{ width: '800px', height: '400px' }} />;
// };

// // Generate initial 1-minute or 5-minute data
// function createInitialMinuteData(interval) {
//   const initialData = [];
//   const currentTime = Math.floor(Date.now() / 1000);
//   let currentPrice = 24000;

//   for (let i = 0; i < 60; i += interval) {
//     const time = currentTime - i * 60;
//     const open = currentPrice;
//     const close = open + getRandomPriceChange();
//     const high = Math.max(open, close) + Math.random() * 0.5;
//     const low = Math.min(open, close) - Math.random() * 0.5;

//     initialData.unshift({ time, open, high, low, close });
//     currentPrice = close;
//   }
//   return initialData;
// }

// function getRandomPriceChange() {
//   return Math.random() * 0.2 - 0.1;
// }

// export default MinuteChart;


