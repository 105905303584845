import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';
// import CSVUploadComponent from '../../components/ChartComponent/CSVUploadComponent';
// import ChartComponent from '../../components/ChartComponent/ChartComponent';
import React, { useState, useEffect } from 'react';
import './ChartPage.css';
import TradingChart from '../../components/ChartComponent/TradingChart';
import MinuteChart from '../../components/ChartComponent/MinuteChart';
import Client from '../../components/Websocket/Client';
import { fetchCandlesData ,transformCandlesData,createVolumeData} from '../../utils/chartdata';
import {Chart} from '../../components/ChartComponent/ChartUtils'; 
import MACDHistogramChart from '../../components/ChartComponent/MACDHistChart';


const ChartPage = () => {
  const [csvData, setCSVData] = useState(null);
  const handleCSVUpload = (data) => {
    console.log("csvData", data)
    setCSVData(data);
  };


  const [candles, setCandles] = useState([]);
  const [volume, setVolume] = useState([]);
  const timestamp = 1723779900;
  const token = 26000;
  const timeFrame = '5min';
  const order_by = 'asc';

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //           const data = await fetchCandlesData(timestamp, token, timeFrame,order_by);
              
  //             const transformedData = transformCandlesData(data);
  //             console.log("trans candle data",transformedData)
              
  //             const volumeData = createVolumeData(transformedData);
  //             // console.log(volumeData);
  //             setVolume(volumeData)
  //             setCandles(transformedData);
  //         } catch (error) {
  //             console.error('Failed to fetch candles data:', error);
  //         }
  //     };

  //     fetchData();
  // },[timeFrame]);

  
//   useEffect(() => {
// },[candles]);

  return (
    <Container>


      {/* <CSVUploadComponent onUpload={handleCSVUpload} /> */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 320,
          }}
        >
          <div className="chart-page">
            <div className="chart-component">

              {/* {csvData && <ChartComponent rawData={csvData} />} */}
              
            </div>
          </div>

        </Paper>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 400,
        }}
      >
        <div className="chart-page">
          <div className="chart-component">

            {/* <TradingChart /> */}
            {/* <MinuteChart/> */}
          </div>
        </div>

      </Paper>
      
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 400,
        }}
      >
        <div className="chart-page">
          <div className="chart-component">

            <Client />

            <MACDHistogramChart/>
          </div>
        </div>

      </Paper>

      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 400,
        }}
      >
        <div className="chart-page">
          <div className="chart-component">
          {/* <Chart priceData={candles} volumeData={volume}></Chart> */}
          </div>
        </div>

      </Paper>
      
    </Container>



  );
};

export default ChartPage;
