




import React, { useEffect, useRef, useState } from "react";
import { createChart, CandlestickSeries, HistogramSeries, LineSeries, AreaSeries, createSeriesMarkers, CrosshairMode } from "lightweight-charts"; // Ensure you're using v5

const formatValue = (value) => {
  if (value >= 1_00_00_000) {
    return (value / 1_00_00_000).toFixed(1) + "Cr";
  } else if (value >= 10_00_000) {
    return (value / 10_00_000).toFixed(1) + "M";
  } else if (value >= 1_00_000) {
    return (value / 1_00_000).toFixed(1) + "L";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  }
  return value.toString(); // always return a string
};


const MultiLineChart = ({ seriesConfig, chartOptions = {}, symbol ,pivotPoints}) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const seriesRefs = useRef({});
  const toolTipRef = useRef(null); // Ref to hold the tooltip element

  useEffect(() => {
    if (!chartContainerRef.current) return;

    // Define the toolTipWidth at the beginning
    const toolTipWidth = 96;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight || 400,  // Dynamic height
      layout: {
        background: { type: "solid", color: "white" },
        textColor: "black",
      },
      leftPriceScale: {
        visible: true,
        borderVisible: false,
        scaleMargins: { top: 0.2, bottom: 0.2 },
        priceFormat: {
          type: 'custom',
          formatter: formatValue,
          minMove: 0.01,
          // Optionally, you can specify precision:
          // precision: 2,
        },
      },
      rightPriceScale: {
        visible: true,
        borderVisible: false,
        scaleMargins: { top: 0.2, bottom: 0.2 },
        priceFormat: {
          type: 'custom',
          formatter: formatValue,
          minMove: 0.01,
          // Optionally, specify precision as well:
          // precision: 2,
        },
      },
      // crosshair: { mode: 1 },
      crosshair: { mode: CrosshairMode.Normal },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
        barSpacing: 8,  // Adjust this for zoom level
        rightOffset: 5,  // Add extra space for future data
      },
      panes: {
        separatorColor: '#f22c3d',
        separatorHoverColor: 'rgba(255, 0, 0, 0.1)',
        // setting this to false will disable the resize of the panes by the user
        enableResize: true,
      },
      ...chartOptions,
    });

    // Ensure only the last N points are visible
    chart.timeScale().setVisibleLogicalRange({
      from: Math.max(0, seriesConfig.price.data.length - 50), // Last 100 data points
      to: seriesConfig.price.data.length - 1,
    });


    chartRef.current = chart;

    // Custom options for the HistogramPane-like effect
    const histogramPanePriceScaleId = 'right'; // Price scale for the histogram series

    Object.entries(seriesConfig).forEach(([seriesId, { type, data, options, symbol, markers }]) => {
      let series;
      const isPrice = seriesId.toLowerCase().includes("price");
      const isCandlestick = seriesId.toLowerCase().includes("candlestick");

      if (type === "line") {
        series = chart.addSeries(LineSeries, {
          ...options,
          priceScaleId: isPrice ? "right" : "left",
        });
      } else if (isCandlestick) {
        series = chart.addSeries(CandlestickSeries, {
          ...options,
          priceScaleId: "left",
        });
      } else if (type === "histogram") {
        series = chart.addSeries(HistogramSeries, {
          ...options,
          priceScaleId: histogramPanePriceScaleId,
        },
          1
          // #pane   .moveToPane(2);
        );
      } else if (type === "area") {
        series = chart.addSeries(AreaSeries, {
          ...options,
        });
      }

      seriesRefs.current[seriesId] = { series, symbol };
      series.setData(data);

      // If markers exist, create markers for the series
      if (markers && markers.length > 0) {
        const formattedMarkers = markers.map(marker => ({
          time: marker.time,
          position: marker.position,
          color: marker.color,
          shape: marker.shape,
          text: marker.text,
        }));

        createSeriesMarkers(series, formattedMarkers);
      }
    });

    // Tooltip setup: Create only once
    if (!toolTipRef.current) {
      const toolTip = document.createElement('div');
      toolTip.style = `width: ${toolTipWidth}px; height: 300px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45); font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
      toolTip.style.background = `rgba(255, 255, 255, 0.25)`;
      toolTip.style.color = 'black';
      toolTip.style.borderColor = 'rgba(239, 83, 80, 1)';
      chartContainerRef.current.appendChild(toolTip);
      toolTipRef.current = toolTip;
    }

    const toolTip = toolTipRef.current; // Get the tooltip reference

    chart.subscribeCrosshairMove(param => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > chartContainerRef.current.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainerRef.current.clientHeight
      ) {
        toolTip.style.display = 'none';
      } else {
        toolTip.style.display = 'block';

        let tooltipContent = `<div style="color: ${'rgba(239, 83, 80, 1)'}">⬤ ${symbol}</div>`;

        Object.entries(seriesRefs.current).forEach(([seriesId, { series, symbol }]) => {
          const data = param.seriesData.get(series);
          const value = data?.value ?? (data?.close ?? "N/A");
          tooltipContent += `<div style="color: ${'black'}">${symbol || seriesId}: ${formatValue(value)}</div>`;
        });

        toolTip.innerHTML = tooltipContent;

        let left = param.point.x; // relative to timeScale
        const timeScaleWidth = chart.timeScale().width();
        const priceScaleWidth = chart.priceScale('left').width();
        const halfTooltipWidth = toolTipWidth / 2;
        left += priceScaleWidth - halfTooltipWidth;
        left = Math.min(left, priceScaleWidth + timeScaleWidth - toolTipWidth);
        left = Math.max(left, priceScaleWidth);

        toolTip.style.left = left + 'px';
        toolTip.style.top = 0 + 'px';
      }
    });

    // Handle window resize
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [seriesConfig, chartOptions]);

  return (
    <div >
      <div ref={chartContainerRef} style={{ width: "100%", height: "100%" ,border: "1px solid #ccc",borderRadius: "1px" }} />
    </div>
  );
};

export default MultiLineChart;



// import React, { useEffect, useRef } from "react";
// import { 
//   createChart, 
//   CandlestickSeries, 
//   LineSeries, 
//   CrosshairMode 
// } from "lightweight-charts";

// const MultiLineChart = ({ seriesConfig, chartOptions = {}, pivotPoints = [] }) => {
//   const chartContainerRef = useRef(null);
//   const chartRef = useRef(null);
//   const seriesRefs = useRef({});
//   const pivotSeriesRefs = useRef([]);

//   useEffect(() => {
//     if (!chartContainerRef.current) return;

//     // Create the chart with desired options
//     const chart = createChart(chartContainerRef.current, {
//       width: chartContainerRef.current.clientWidth,
//       height: chartContainerRef.current.clientHeight || 400,
//       layout: { 
//         background: { type: "solid", color: "white" }, 
//         textColor: "black" 
//       },
//       leftPriceScale: { visible: true },
//       rightPriceScale: { visible: true },
//       crosshair: { mode: CrosshairMode.Normal },
//       timeScale: {
//         timeVisible: true,
//         secondsVisible: true,
//         barSpacing: 8,
//         rightOffset: 5,
//       },
//       ...chartOptions,
//     });
//     chartRef.current = chart;

//     // Add the main series from seriesConfig
//     Object.entries(seriesConfig).forEach(([seriesId, { type, data, options }]) => {
//       let series;
//       if (type === "candlestick") {
//         series = chart.addCandlestickSeries(options);
//       } else if (type === "line") {
//         series = chart.addSeries(LineSeries,{
//           ...options,
//           priceScaleId: seriesId.toLowerCase().includes("price") ? "right" : "left",
//         });
//       }
//       seriesRefs.current[seriesId] = series;
//       series.setData(data);
//     });

//     // Function to add/update pivot point lines
//     const updatePivotLines = () => {
//       // Remove old pivot series
//       pivotSeriesRefs.current.forEach(pivotSeries => pivotSeries.remove());
//       pivotSeriesRefs.current = [];

//       // Get the current visible time range from the chart
//       const visibleRange = chart.timeScale().getVisibleRange();
//       if (!visibleRange) return;
//       const from = visibleRange.from;
//       const to = visibleRange.to;

//       pivotPoints.forEach(pivot => {
//         const pivotSeries = chart.addSeries(LineSeries,{
//           color: pivot.color || "blue",
//           lineWidth: 1,
//           crosshairMarkerVisible: false,
//           // Optionally, you could disable interactions for pivot lines
//           // enableMouseTracking: false,
//         });

//         // We use the visible range to extend the pivot line
//         pivotSeries.setData([
//           { time: from, value: pivot.value },
//           { time: to, value: pivot.value }
//         ]);

//         pivotSeriesRefs.current.push(pivotSeries);
//       });
//     };

//     // Initially draw pivot lines
//     updatePivotLines();

//     // Update pivot lines when the visible time range changes
//     chart.timeScale().subscribeVisibleLogicalRangeChange(() => {
//       updatePivotLines();
//     });

//     // Resize listener to update chart width
//     const handleResize = () => {
//       chart.applyOptions({ width: chartContainerRef.current.clientWidth });
//     };
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       chart.remove();
//     };
//   }, [seriesConfig, chartOptions, pivotPoints]);

//   return (
//     <div style={{ position: "relative", width: "100%", height: 400, border: "1px solid #ccc", borderRadius: "10px" }}>
//       <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }} />
//     </div>
//   );
// };

// export default MultiLineChart;
