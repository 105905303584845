import { useEffect, useState, useRef } from 'react';

const useMarketData = (websocketUrl) => {
  const [data, setData] = useState(createInitialMinuteData());
  const wsRef = useRef(null);

  useEffect(() => {
    wsRef.current = new WebSocket(websocketUrl);

    wsRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    wsRef.current.onmessage = (event) => {
      const newPriceData = JSON.parse(event.data);
      if (newPriceData && newPriceData.price && newPriceData.timestamp) {
        updateData(newPriceData);
      } else {
        console.warn('Invalid data received:', newPriceData);
      }
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket closed');
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [websocketUrl]);

  const updateData = (newPriceData) => {
    setData((prevData) => {
      const lastClose = prevData[prevData.length - 1]?.close || newPriceData.price;
      const newTime = Math.floor(newPriceData.timestamp);
      
      const newCandle = {
        time: newTime,
        open: lastClose,
        high: Math.max(lastClose, newPriceData.price),
        low: Math.min(lastClose, newPriceData.price),
        close: newPriceData.price,
      };

      const updatedData = [...prevData, newCandle];
      return updatedData.length > 5000 ? updatedData.slice(1) : updatedData;
    });
  };

  return data;
};

function createInitialMinuteData() {
  const initialData = [];
  const currentTime = Math.floor(Date.now() / 1000);
  let currentPrice = 24000;

  for (let i = 0; i < 60; i++) {
    const open = currentPrice;
    const close = open + (Math.random() * 0.2 - 0.1);
    const high = Math.max(open, close) + Math.random() * 0.5;
    const low = Math.min(open, close) - Math.random() * 0.5;
    initialData.push({ time: currentTime - ((60 - i) * 60), open, high, low, close });
    currentPrice = close;
  }

  return initialData;
}

export default useMarketData;
