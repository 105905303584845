import React from 'react';
import ChartComponent from '../../components/Chart/TradingView/TVChart';
import useMarketData from '../../components/Chart/TradingView/useMarketData';
import TradingViewOIChart from '../../components/Chart/TradingView/TVOiChart';

const TickChart = ({ websocketUrl }) => {
  const data = useMarketData(websocketUrl);

  return (
    <>
      <div>
        <h1>Realtime Market Chart</h1>
        <ChartComponent data={data} />
      </div>
      <div>
        {/* <TradingViewOIChart /> */}
      </div>
    </>
  );
};

export default TickChart;
