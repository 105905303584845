import React, { useState, useEffect } from 'react';
import { useWebSocket } from '../../hooks/useWebSocket';
import MinuteChart from '../ChartComponent/MinuteChart';
import DynamicMACDCharts from '../ChartComponent/MACDHistChart';

const WebSocketComponent = () => {
  const [token, setToken] = useState(26000); // Default token
  const [historicalData, setHistoricalData] = useState({});
  const [liveData, setLiveData] = useState({});
  const [macdData, setMacdData] = useState({}); // State for MACD histogram data

  const { isConnected, latestMessage, wsRef } = useWebSocket(
    process.env.NODE_ENV === 'production'
      ? 'wss://ceegees-algo-io.com'
      : 'ws://localhost:8766'
  );

  // Different time intervals (in minutes) for your price charts.
  const intervals = [30, 60];

  // Subscribe to the token.
  const subscribeToken = (newToken) => {
    if (wsRef.current?.send) {
      wsRef.current.send(JSON.stringify({ subscribe: newToken }));
      console.log('Subscribed to token:', newToken);
    }
  };

  // Request historical data.
  const fetchHistoricalData = (newToken) => {
    if (wsRef.current?.send) {
      wsRef.current.send(JSON.stringify({ request: 'history', token: newToken }));
      console.log('Requested historical data for token:', newToken);
    }
  };

  // Subscribe to MACD histogram data.
  const subscribeMacd = (newToken) => {
    if (wsRef.current?.send) {
      // Customize the timeframe configurations as needed.
      const macdSubscription = {
        subscribe: "macd_histogram",
        token: newToken,
        timeframes: [
          { timeframe: "1min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
          { timeframe: "2min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
          { timeframe: "3min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
          { timeframe: "5min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 }
        ]
      };
      wsRef.current.send(JSON.stringify(macdSubscription));
      console.log('Subscribed to MACD histogram for token:', newToken, macdSubscription);
    }
  };

  // Handle token input changes.
  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  // Handle form submission for subscribing.
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isConnected && token) {
      const numToken = Number(token);
      fetchHistoricalData(numToken);
      subscribeToken(numToken);
      // Optionally subscribe to MACD histogram data right away.
      subscribeMacd(numToken);
    }
  };

  // Process incoming WebSocket messages.
  useEffect(() => {
    if (latestMessage) {
      // Historical data messages.
      if (latestMessage.type === 'history' && latestMessage.token && latestMessage.data) {
        console.log('Received historical data:', latestMessage.data.length, 'candles');
        setHistoricalData((prev) => ({
          ...prev,
          [latestMessage.token]: latestMessage.data,
        }));
      }
      // MACD histogram messages.
      else if (latestMessage.type === 'macd_histogram' && latestMessage.token && latestMessage.data) {
        console.log('Received MACD histogram data:', latestMessage.data);
        setMacdData((prev) => ({
          ...prev,
          [latestMessage.token]: latestMessage.data,
        }));
      }
      // Tick data messages.
      else if (latestMessage.token && latestMessage.price && latestMessage.timestamp) {
        console.log('Received new tick:', latestMessage);
        const newTick = {
          time: latestMessage.timestamp,
          open: latestMessage.price,
          high: latestMessage.price,
          low: latestMessage.price,
          close: latestMessage.price,
        };
        setLiveData((prev) => {
          const updatedData = prev[latestMessage.token]
            ? [...prev[latestMessage.token], newTick]
            : [newTick];
          return { ...prev, [latestMessage.token]: updatedData };
        });
      }
    }
  }, [latestMessage]);

  // Retrieve history and tick data for the given token.
  const history = historicalData[token] || [];
  const tickData = liveData[token] || [];
  const latestTick = tickData.length > 0 ? tickData[tickData.length - 1] : null;
  const tokenMacdData = macdData[token];

  return (
    <div>
      <h2>WebSocket</h2>
      <div>Status: {isConnected ? '🟢 Connected' : '🔴 Disconnected'}</div>

      <form onSubmit={handleSubmit}>
        <label>
          Token:
          <input
            type="number"
            value={token}
            onChange={handleTokenChange}
            disabled={!isConnected}
          />
        </label>
        <button type="submit" disabled={!isConnected}>
          Subscribe
        </button>
      </form>

      {/* Optional separate button for subscribing to MACD histogram */}
      <button onClick={() => subscribeMacd(Number(token))} disabled={!isConnected}>
        Subscribe MACD Histogram
      </button>

      <div style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}>
        <strong>Latest Message:</strong> {JSON.stringify(latestMessage)}
      </div>

      {/* Display MACD histogram raw data */}
      <div style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}>
        <strong>MACD Histogram Data:</strong>
        <pre>{JSON.stringify(macdData, null, 2)}</pre>
      </div>

      {/* Render price charts (using your existing MinuteChart component) */}
      {intervals.map((intv) => (
        <MinuteChart
          key={intv}
          historyData={history.length > 0 ? history : tickData}
          priceData={latestTick}
          interval={intv}
        />
      ))}

      <div style={{ marginTop: '20px' }}>
        {tokenMacdData ? (
          <DynamicMACDCharts macdData={tokenMacdData} />
        ) : (
          <p>No MACD data available.</p>
        )}
      </div>
    </div>
  );
};

export default WebSocketComponent;






// import React, { useState, useEffect } from 'react';
// import { useWebSocket } from '../../hooks/useWebSocket';
// import MinuteChart from '../ChartComponent/MinuteChart';

// const WebSocketComponent = () => {
//   const [token, setToken] = useState(26000); // Default token
//   const [historicalData, setHistoricalData] = useState({});
//   const [liveData, setLiveData] = useState({});
//   const { isConnected, latestMessage, wsRef } = useWebSocket(
//     process.env.NODE_ENV === 'production'
//       ? 'wss://ceegees-algo-io.com'
//       : 'ws://localhost:8766'
//   );

//   const intervals = [1, 3, 5, 15, 60]; // Different time intervals (in minutes)

//   // Send subscription message to WebSocket.
//   const subscribeToken = (newToken) => {
//     if (wsRef.current?.send) {
//       wsRef.current.send(JSON.stringify({ subscribe: newToken }));
//       console.log('Subscribed to token:', newToken);
//     }
//   };

//   // Request historical data for the token.
//   const fetchHistoricalData = (newToken) => {
//     if (wsRef.current?.send) {
//       wsRef.current.send(JSON.stringify({ request: 'history', token: newToken }));
//       console.log('Requested historical data for token:', newToken);
//     }
//   };

//   // Handle token input changes.
//   const handleTokenChange = (e) => {
//     setToken(e.target.value);
//   };

//   // Handle form submission for subscribing.
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (isConnected && token) {
//       fetchHistoricalData(Number(token));
//       subscribeToken(Number(token));
//     }
//   };

//   // Handle incoming WebSocket messages.
//   useEffect(() => {
//     if (latestMessage) {
//       // If a history message arrives, store it.
//       if (latestMessage.type === 'history' && latestMessage.token && latestMessage.data) {
//         console.log('Received historical data:', latestMessage.data.length, 'candles');
//         setHistoricalData((prev) => ({
//           ...prev,
//           [latestMessage.token]: latestMessage.data,
//         }));
//       }
//       // If a tick arrives, convert it to a candlestick structure and store it.
//       else if (
//         latestMessage.token &&
//         latestMessage.price &&
//         latestMessage.timestamp
//       ) {
//         console.log('Received new tick:', latestMessage);
//         const newTick = {
//           time: latestMessage.timestamp,
//           open: latestMessage.price,
//           high: latestMessage.price,
//           low: latestMessage.price,
//           close: latestMessage.price,
//         };
//         setLiveData((prev) => {
//           const updatedData = prev[latestMessage.token]
//             ? [...prev[latestMessage.token], newTick]
//             : [newTick];
//           return { ...prev, [latestMessage.token]: updatedData };
//         });
//       }
//     }
//   }, [latestMessage]);

//   // For the given token, retrieve history and tick data.
//   const history = historicalData[token] || [];
//   const tickData = liveData[token] || [];
//   // Use the last tick as the latest price data.
//   const latestTick = tickData.length > 0 ? tickData[tickData.length - 1] : null;

//   return (
//     <div>
//       <h2>WebSocket</h2>
//       <div>Status: {isConnected ? '🟢 Connected' : '🔴 Disconnected'}</div>

//       <form onSubmit={handleSubmit}>
//         <label>
//           Token:
//           <input
//             type="number"
//             value={token}
//             onChange={handleTokenChange}
//             disabled={!isConnected}
//           />
//         </label>
//         <button type="submit" disabled={!isConnected}>
//           Subscribe
//         </button>
//       </form>

//       <div style={{ border: '1px solid #ccc', padding: '10px', marginTop: '10px' }}>
//         <strong>Latest Message:</strong> {JSON.stringify(latestMessage)}
//       </div>
// {/* 
//       {intervals.map((interval) => (
//         <MinuteChart
//           key={interval}
//           historyData={history.length > 0 ? history : tickData}
//           priceData={latestTick}
//           interval={interval}
//         />
//       ))} */}
//       <MinuteChart
//           key={30}
//           historyData={history.length > 0 ? history : tickData}
//           priceData={latestTick}
//           interval={30}
//         />
//         <MinuteChart
//           key={60}
//           historyData={history.length > 0 ? history : tickData}
//           priceData={latestTick}
//           interval={60}
//         />
//     </div>
//   );
// };

// export default WebSocketComponent;

