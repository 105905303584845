import React, { useState, useEffect, useMemo } from "react";
import {
    Container,
    Grid,
    Box,
    TextField,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Paper,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchOiAnalyz, fetchOptionMapsByTradeDate } from "../../utils/services";
import { getToken } from "../../utils/helpers";
import MultiLineOIChart from "../../components/Chart/TradingView/TVChart";
import DynamicMACDCharts from "../../components/ChartComponent/MACDHistChart";
import { useWebSocket } from "../../hooks/useWebSocket";
import moment from "moment";

// MUI Icons for layout toggle
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const formatToUTCTimestamp = (datetime) => {
    if (!datetime) return null;
    return Math.floor(new Date(datetime).getTime() / 1000) + 19800; // Convert IST to UTC
};

const REFRESH_INTERVAL = 20 * 1000; // 20 seconds
const todayDate = new Date().toISOString().split("T")[0];

const formatValue = (value) => {
    if (value >= 1_00_00_000) {
        return (value / 1_00_00_000).toFixed(1) + "Cr";
    } else if (value >= 10_00_000) {
        return (value / 10_00_000).toFixed(1) + "M";
    } else if (value >= 1_00_000) {
        return (value / 1_00_000).toFixed(1) + "L";
    } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + "K";
    }
    return value;
};

const TraderPageTV = () => {
    // --------------------- State Definitions --------------------- //
    const [token, setToken] = useState(26000);
    const [historicalData, setHistoricalData] = useState({});
    const [liveData, setLiveData] = useState({});

    const [date, setDate] = useState(() => {
        const localDate = new Date();
        const options = { timeZone: "Asia/Kolkata" };
        const istDate = new Date(localDate.toLocaleString("en-US", options));
        return istDate;
    });
    const [stockData1, setPutData] = useState([]);
    const [stockData2, setCallData] = useState([]);
    const [symbol1, setPutSymbol] = useState("");
    const [symbol2, setCallSymbol] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const authToken = getToken();
    const formattedDate = moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");

    const [PutMapData, setPutMapData] = useState([]);
    const [CallMapData, setCallMapData] = useState([]);
    const [selectedPutOption, setSelectedPutOption] = useState("");
    const [selectedCallOption, setSelectedCallOption] = useState("");
    const [CallToken, setCallToken] = useState(null);
    const [PutToken, setPutToken] = useState(null);
    const [PutoiThreshold, setPutoiThreshold] = useState(1);
    const [CalloiThreshold, setCalloiThreshold] = useState(1);

    // New state for MACD histogram data (keyed by token)
    const [macdData, setMacdData] = useState({});

    // New state for layout mode: "sideBySide" (default) or "stacked"
    const [layoutMode, setLayoutMode] = useState("sideBySide");

    // --------------------- WebSocket Hook & Functions --------------------- //
    const { isConnected, latestMessage, wsRef } = useWebSocket(
        process.env.NODE_ENV === "production"
            ? "wss://zinvest.in/ws/"
            // : "ws://localhost:8766"
            : "wss://zinvest.in/ws/ "
    );

    const subscribeToken = (newToken) => {
        if (wsRef.current?.send) {
            wsRef.current.send(JSON.stringify({ subscribe: newToken }));
            console.log("Subscribed to token:", newToken);
        }
    };

    const fetchHistoricalData = (newToken) => {
        if (wsRef.current?.send) {
            wsRef.current.send(JSON.stringify({ request: "history", token: newToken }));
            console.log("Requested historical data for token:", newToken);
        }
    };

    const subscribeMacd = (newToken) => {
        if (wsRef.current?.send) {
            const macdSubscription = {
                subscribe: "macd_histogram",
                token: newToken,
                timeframes: [
                    { timeframe: "1min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
                    { timeframe: "2min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
                    { timeframe: "3min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
                    { timeframe: "5min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
                ],
            };
            wsRef.current.send(JSON.stringify(macdSubscription));
            console.log("Subscribed to MACD histogram for token:", newToken, macdSubscription);
        }
    };

    // --------------------- Handlers --------------------- //
    const handleTokenChange = (e) => {
        setToken(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isConnected && token) {
            const numToken = Number(token);
            fetchHistoricalData(numToken);
            subscribeToken(numToken);
            subscribeMacd(numToken);
        }
    };

    const handlePutSelectChange = (event) => {
        const selectedPut = JSON.parse(event.target.value);
        setPutToken(selectedPut.token);
        setSelectedPutOption(selectedPut.strike);
        fetchDataByToken(selectedPut.token, "PE");
    };

    const handleCallSelectChange = (event) => {
        const selectedCall = JSON.parse(event.target.value);
        setCallToken(selectedCall.token);
        setSelectedCallOption(selectedCall.strike);
        fetchDataByToken(selectedCall.token, "CE");
    };

    const handleDateChange = (selectedDate) => {
        const options = { timeZone: "Asia/Kolkata", hour12: false };
        const istDate = new Date(selectedDate.toLocaleString("en-US", options));
        setDate(istDate);
    };

    // --------------------- Data Fetching & Polling --------------------- //
    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            setErrorMessage(null);
            try {
                const formattedDate = moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");
                const response = await fetchOiAnalyz(authToken, formattedDate);
                console.log("API Response:", response);
                if (response?.data?.length >= 2) {
                    const [put, call] = response.data;
                    setPutData(put.data);
                    setCallData(call.data);
                    setPutSymbol(put.trading_symbol);
                    setCallSymbol(call.trading_symbol);
                    setPutToken(put.token);
                    setCallToken(call.token);
                } else {
                    setErrorMessage("No data available for the selected date.");
                    setPutData([]);
                    setCallData([]);
                    setPutSymbol("");
                    setCallSymbol("");
                    setPutToken(null);
                    setCallToken(null);
                }
            } catch (error) {
                console.error("Error fetching OI data:", error);
                setErrorMessage("Failed to fetch data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };
        fetchInitialData();
    }, [authToken, formattedDate]);

    useEffect(() => {
        const getCurrentTimeInIST = () => {
            const now = new Date();
            return new Date(now.getTime() + 5.5 * 60 * 60 * 1000);
        };

        const isWithinTradingHours = () => {
            const nowIST = getCurrentTimeInIST();
            const startIST = new Date(nowIST);
            startIST.setHours(9, 0, 0, 0);
            const endIST = new Date(nowIST);
            endIST.setHours(15, 30, 0, 0);
            return nowIST >= startIST && nowIST <= endIST;
        };

        if (date.toISOString().split("T")[0] === todayDate) {
            const interval = setInterval(() => {
                console.log("Polling latest OI data...");
                if (CallToken) {
                    fetchDataByToken(CallToken, "CE");
                }
                if (PutToken) {
                    fetchDataByToken(PutToken, "PE");
                }
            }, REFRESH_INTERVAL);
            return () => clearInterval(interval);
        }
    }, [date, CallToken, PutToken]);

    const fetchDataByToken = async (token, type) => {
        if (!token) return;
        setLoading(true);
        setErrorMessage(null);
        try {
            const formattedDate = moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");
            const response = await fetchOiAnalyz(authToken, formattedDate, token, type);
            console.log(`API Response for ${type}:`, response);
            if (response?.data?.length > 0) {
                const optionData = response.data[0];
                if (type === "PE") {
                    setPutData(optionData.data);
                    setPutSymbol(optionData.trading_symbol);
                    setPutToken(optionData.token);
                } else {
                    setCallData(optionData.data);
                    setCallSymbol(optionData.trading_symbol);
                    setCallToken(optionData.token);
                }
            } else {
                setErrorMessage(`No ${type} data available for the selected option.`);
            }
        } catch (error) {
            console.error(`Error fetching ${type} OI data:`, error);
            setErrorMessage(`Failed to fetch ${type} data. Please try again later.`);
        } finally {
            setLoading(false);
        }
    };

    // --------------------- Automatic MACD Subscription --------------------- //
    useEffect(() => {
        const subscribeWhenReady = () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                subscribeMacd(Number(token));
            } else {
                setTimeout(subscribeWhenReady, 100);
            }
        };
        if (isConnected && token) {
            subscribeWhenReady();
        }
    }, [isConnected, token, wsRef]);

    // --------------------- WebSocket Incoming Message Processing --------------------- //
    useEffect(() => {
        if (latestMessage) {
            // Log every incoming websocket message
            console.log("WebSocket data received:", latestMessage);

            if (latestMessage.type === "history" && latestMessage.token && latestMessage.data) {
                console.log("Received historical data:", latestMessage.data.length, "candles");
            } else if (latestMessage.type === "macd_histogram" && latestMessage.token && latestMessage.data) {
                console.log("Received MACD histogram data:", latestMessage.data);
                setMacdData((prev) => ({
                    ...prev,
                    [latestMessage.token]: latestMessage.data,
                }));
            } else if (latestMessage.token && latestMessage.price && latestMessage.timestamp) {
                console.log("Received new tick:", latestMessage);
                const newTick = {
                    time: latestMessage.timestamp,
                    open: latestMessage.price,
                    high: latestMessage.price,
                    low: latestMessage.price,
                    close: latestMessage.price,
                };
                setLiveData((prev) => {
                    const updatedData = prev[latestMessage.token]
                        ? [...prev[latestMessage.token], newTick]
                        : [newTick];
                    return { ...prev, [latestMessage.token]: updatedData };
                });
            }
        }
    }, [latestMessage]);

    // --------------------- Derived Data & Config --------------------- //
    const history = historicalData[token] || [];
    const tickData = liveData[token] || [];
    const latestTick = tickData.length > 0 ? tickData[tickData.length - 1] : null;

    // --------------------- Option Maps --------------------- //
    useEffect(() => {
        fetchOptionMapsByTradeDate(authToken, formattedDate)
            .then((data) => {
                console.log("Fetched Option Map Data:", data.data);
                setPutMapData(data.peData);
                setCallMapData(data.ceData);
            })
            .catch((error) => console.error("API Error:", error));
    }, [date, authToken]);

    const getSeriesConfig = (stockData, symbol, oiThreshold) => {
        if (!symbol) return {};
        const formatData = (data, key) => {
            return data
                .map(({ datetime, [key]: value, trend }) => {
                    const timestampIST = new Date(datetime).getTime() / 1000;
                    const timestampUTC = timestampIST + 19800;
                    return {
                        time: timestampUTC,
                        value: parseFloat(value),
                        trend: trend,
                    };
                })
                .filter((item) => !isNaN(item.value));
        };
        const markers = stockData
            .filter(({ trend, oi_perc_change }) => trend !== 0 && Math.abs(oi_perc_change) >= oiThreshold)
            .map(({ trend, datetime }) => ({
                time: formatToUTCTimestamp(datetime),
                position: trend === 1 ? "belowBar" : "aboveBar",
                color: trend === 1 ? "#2196F3" : "#e91e63",
                shape: trend === 1 ? "arrowUp" : "arrowDown",
                text: `${trend === 1 ? "B" : "S"} @ ${formatValue(
                    stockData.find((d) => formatToUTCTimestamp(d.datetime) === formatToUTCTimestamp(datetime))?.ltp
                )}`,
            }));
        return {
            price: {
                type: "line",
                data: formatData(stockData, "ltp"),
                options: {
                    color: "#1f77b4",
                    lineWidth: 2,
                    priceScaleId: "left",
                    symbol: symbol,
                },
                markers: markers,
            },
            openInterest: {
                type: "line",
                data: formatData(stockData, "open_interest"),
                options: {
                    color: "#ff7f0e",
                    lineWidth: 2,
                    priceScaleId: "right",
                    symbol: symbol,
                },
            },
            oi_perc_change: {
                type: "histogram",
                data: stockData
                    .map(({ datetime, oi_perc_change }) => ({
                        time: new Date(datetime).getTime() / 1000 + 19800,
                        value: parseFloat(oi_perc_change),
                        color: oi_perc_change > 0 ? "green" : oi_perc_change < 0 ? "red" : "gray",
                    }))
                    .filter((item) => !isNaN(item.value)),
                options: {
                    priceScaleId: "left",
                    lineWidth: 1,
                    symbol: symbol,
                },
            },
        };
    };

    const seriesConfig1 = useMemo(() => {
        if (symbol1) {
            return getSeriesConfig(stockData1, symbol1, PutoiThreshold);
        }
        return {};
    }, [stockData1, symbol1]);

    const seriesConfig2 = useMemo(() => {
        if (symbol2) {
            return getSeriesConfig(stockData2, symbol2, CalloiThreshold);
        }
        return {};
    }, [stockData2, symbol2]);

    const pivotPoints = [
        { price: 300, label: "Pivot", color: "red" },
        { price: 200, label: "Resistance 1", color: "green" },
        { price: 100, label: "Support 1", color: "blue" },
    ];

    // --------------------- Render --------------------- //
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    alignItems: "center",
                    paddingTop:.2
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Box sx={{ width: "6%" }}>
                        <FormControl fullWidth>
                            <InputLabel id="pe-strike">Put Strike</InputLabel>
                            <Select
                                labelId="pe-strike-price"
                                value={JSON.stringify({ strike: selectedPutOption, token: PutToken })}
                                onChange={handlePutSelectChange}
                                label="Select Put Option"
                                MenuProps={{
                                    PaperProps: { style: { maxHeight: 200, overflowY: "auto" } },
                                }}
                            >
                                {PutMapData &&
                                    Object.entries(PutMapData).map(([strike, token]) => (
                                        <MenuItem key={token} value={JSON.stringify({ strike, token })}>
                                            {strike}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ width: "135px" }}>
                            <DatePicker
                                value={date}
                                onChange={handleDateChange}
                                components={{
                                    OpenPickerIcon: CalendarTodayIcon, // Custom icon if needed
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            endAdornment: params.InputProps.endAdornment,
                                            sx: { padding: 0, width: "100%" },
                                        }}
                                        sx={{ width: "100%", padding: 0, minWidth: "36px" }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <IconButton
                            onClick={() =>
                                setLayoutMode(layoutMode === "sideBySide" ? "stacked" : "sideBySide")
                            }
                        >
                            {layoutMode === "sideBySide" ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            position: "fixed",
                            // top: 10,
                            right: 100,
                            display: "flex",
                            alignItems: "center",
                            // gap: 1,
                            zIndex: 9999,
                        }}
                    >
                        <Box
                            sx={{
                                width: 12,
                                height: 12,
                                borderRadius: "50%",
                                backgroundColor: isConnected ? "green" : "red",
                            }}
                        />
                        {/* <Typography variant="caption">
                    {isConnected ? "Connected" : "Disconnected"}
                </Typography> */}
                    </Box>


                    <Box sx={{ width: "6%" }}>
                        <FormControl fullWidth>
                            <InputLabel id="ce-strike">Call Strike</InputLabel>
                            <Select
                                labelId="ce-strike-price"
                                value={JSON.stringify({ strike: selectedCallOption, token: CallToken })}
                                onChange={handleCallSelectChange}
                                label="Select Call Option"
                                MenuProps={{
                                    PaperProps: { style: { maxHeight: 200, overflowY: "auto" } },
                                }}
                            >
                                {CallMapData &&
                                    Object.entries(CallMapData).map(([strike, token]) => (
                                        <MenuItem key={token} value={JSON.stringify({ strike, token })}>
                                            {strike}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {errorMessage && (
                    <Box sx={{ color: "red", textAlign: "center", mt: 1 }}>
                        {errorMessage}
                    </Box>
                )}
                {loading && (
                    <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                        <CircularProgress size={24} />
                    </Box>
                )}
            </Box>

            <Grid container spacing={2} sx={{ mb: 2 }} paddingTop={.5}>
                <Grid item xs={12} md={layoutMode === "sideBySide" ? 6 : 12}>
                    <Box sx={{ width: "100%", height: "350px" }}>
                        {symbol1 && stockData1.length > 0 && (
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <MultiLineOIChart
                                    seriesConfig={seriesConfig1}
                                    pivotPoints={pivotPoints}
                                    width="100%"
                                    height={400}
                                    symbol={symbol1}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={layoutMode === "sideBySide" ? 6 : 12}>
                    <Box sx={{ width: "100%", height: "350px" }}>
                        {symbol2 && stockData2.length > 0 && (
                            <Box sx={{ width: "100%", height: "100%" }}>
                                <MultiLineOIChart
                                    seriesConfig={seriesConfig2}
                                    pivotPoints={pivotPoints}
                                    width="100%"
                                    height={400}
                                    symbol={symbol2}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid  paddingTop={4}>
            <Box sx={{ mt: 2 }}>
                {macdData[token] ? (
                    <DynamicMACDCharts macdData={macdData[token]} />
                ) : (
                    <Typography align="center" variant="body2">
                        No MACD data available.
                    </Typography>
                )}
            </Box>
            </Grid>
        </LocalizationProvider>
    );
};

export default TraderPageTV;









// import React, { useState, useEffect, useMemo } from "react";
// import {
//     Container, Grid, Box, TextField, CircularProgress, Select,
//     MenuItem, FormControl, InputLabel, IconButton
// } from "@mui/material";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { fetchOiAnalyz, fetchOptionMapsByTradeDate } from "../../utils/services";
// import { getToken } from "../../utils/helpers";
// import MultiLineOIChart from "../../components/Chart/TradingView/TVChart";
// import DynamicMACDCharts from "../../components/ChartComponent/MACDHistChart";
// import { useWebSocket } from "../../hooks/useWebSocket";
// import moment from "moment";

// // MUI Icons for layout toggle
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// export const formatToUTCTimestamp = (datetime) => {
//     if (!datetime) return null;
//     return Math.floor(new Date(datetime).getTime() / 1000) + 19800; // Convert IST to UTC
// };

// const REFRESH_INTERVAL = 20 * 1000; // 20 seconds
// const todayDate = new Date().toISOString().split("T")[0];

// const formatValue = (value) => {
//     if (value >= 1_00_00_000) {
//         return (value / 1_00_00_000).toFixed(1) + "Cr";
//     } else if (value >= 10_00_000) {
//         return (value / 10_00_000).toFixed(1) + "M";
//     } else if (value >= 1_00_000) {
//         return (value / 1_00_000).toFixed(1) + "L";
//     } else if (value >= 1000) {
//         return (value / 1000).toFixed(1) + "K";
//     }
//     return value;
// };

// const TraderPageTV = () => {
//     // --------------------- State Definitions --------------------- //
//     const [token, setToken] = useState(26000);
//     const [historicalData, setHistoricalData] = useState({});
//     const [liveData, setLiveData] = useState({});

//     const [date, setDate] = useState(() => {
//         const localDate = new Date();
//         const options = { timeZone: "Asia/Kolkata" };
//         const istDate = new Date(localDate.toLocaleString("en-US", options));
//         return istDate;
//     });
//     const [stockData1, setPutData] = useState([]);
//     const [stockData2, setCallData] = useState([]);
//     const [symbol1, setPutSymbol] = useState("");
//     const [symbol2, setCallSymbol] = useState("");
//     const [errorMessage, setErrorMessage] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const authToken = getToken();
//     const formattedDate = moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");

//     const [PutMapData, setPutMapData] = useState([]);
//     const [CallMapData, setCallMapData] = useState([]);
//     const [selectedPutOption, setSelectedPutOption] = useState("");
//     const [selectedCallOption, setSelectedCallOption] = useState("");
//     const [CallToken, setCallToken] = useState(null);
//     const [PutToken, setPutToken] = useState(null);
//     const [PutoiThreshold, setPutoiThreshold] = useState(1);
//     const [CalloiThreshold, setCalloiThreshold] = useState(1);

//     // New state for MACD histogram data (keyed by token)
//     const [macdData, setMacdData] = useState({});

//     // New state for layout mode: "sideBySide" (default) or "stacked"
//     const [layoutMode, setLayoutMode] = useState("sideBySide");

//     // --------------------- WebSocket Hook & Functions --------------------- //
//     const { isConnected, latestMessage, wsRef } = useWebSocket(
//         process.env.NODE_ENV === "production"
//             ? "wss://ceegees-algo-io.com"
//             : "ws://localhost:8766"
//     );

//     const subscribeToken = (newToken) => {
//         if (wsRef.current?.send) {
//             wsRef.current.send(JSON.stringify({ subscribe: newToken }));
//             console.log("Subscribed to token:", newToken);
//         }
//     };

//     const fetchHistoricalData = (newToken) => {
//         if (wsRef.current?.send) {
//             wsRef.current.send(JSON.stringify({ request: "history", token: newToken }));
//             console.log("Requested historical data for token:", newToken);
//         }
//     };

//     const subscribeMacd = (newToken) => {
//         if (wsRef.current?.send) {
//             const macdSubscription = {
//                 subscribe: "macd_histogram",
//                 token: newToken,
//                 timeframes: [
//                     { timeframe: "1min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
//                     { timeframe: "2min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
//                     { timeframe: "3min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
//                     { timeframe: "5min", short_window: 12, long_window: 26, signal_window: 9, shift: 1, num_rows: 7 },
//                 ],
//             };
//             wsRef.current.send(JSON.stringify(macdSubscription));
//             console.log("Subscribed to MACD histogram for token:", newToken, macdSubscription);
//         }
//     };

//     // --------------------- Handlers --------------------- //
//     const handleTokenChange = (e) => {
//         setToken(e.target.value);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (isConnected && token) {
//             const numToken = Number(token);
//             fetchHistoricalData(numToken);
//             subscribeToken(numToken);
//             subscribeMacd(numToken);
//         }
//     };

//     const handlePutSelectChange = (event) => {
//         const selectedPut = JSON.parse(event.target.value);
//         setPutToken(selectedPut.token);
//         setSelectedPutOption(selectedPut.strike);
//         fetchDataByToken(selectedPut.token, "PE");
//     };

//     const handleCallSelectChange = (event) => {
//         const selectedCall = JSON.parse(event.target.value);
//         setCallToken(selectedCall.token);
//         setSelectedCallOption(selectedCall.strike);
//         fetchDataByToken(selectedCall.token, "CE");
//     };

//     const handleDateChange = (selectedDate) => {
//         const options = { timeZone: "Asia/Kolkata", hour12: false };
//         const istDate = new Date(selectedDate.toLocaleString("en-US", options));
//         setDate(istDate);
//     };

//     // --------------------- Data Fetching & Polling --------------------- //
//     useEffect(() => {
//         const fetchInitialData = async () => {
//             setLoading(true);
//             setErrorMessage(null);
//             try {
//                 const formattedDate = moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");
//                 const response = await fetchOiAnalyz(authToken, formattedDate);
//                 console.log("API Response:", response);
//                 if (response?.data?.length >= 2) {
//                     const [put, call] = response.data;
//                     setPutData(put.data);
//                     setCallData(call.data);
//                     setPutSymbol(put.trading_symbol);
//                     setCallSymbol(call.trading_symbol);
//                     setPutToken(put.token);
//                     setCallToken(call.token);
//                 } else {
//                     setErrorMessage("No data available for the selected date.");
//                     setPutData([]);
//                     setCallData([]);
//                     setPutSymbol("");
//                     setCallSymbol("");
//                     setPutToken(null);
//                     setCallToken(null);
//                 }
//             } catch (error) {
//                 console.error("Error fetching OI data:", error);
//                 setErrorMessage("Failed to fetch data. Please try again later.");
//             } finally {
//                 setLoading(false);
//             }
//         };
//         fetchInitialData();
//     }, [authToken, formattedDate]);

//     useEffect(() => {
//         const getCurrentTimeInIST = () => {
//             const now = new Date();
//             return new Date(now.getTime() + 5.5 * 60 * 60 * 1000);
//         };

//         const isWithinTradingHours = () => {
//             const nowIST = getCurrentTimeInIST();
//             const startIST = new Date(nowIST);
//             startIST.setHours(9, 0, 0, 0);
//             const endIST = new Date(nowIST);
//             endIST.setHours(15, 30, 0, 0);
//             return nowIST >= startIST && nowIST <= endIST;
//         };

//         if (date.toISOString().split("T")[0] === todayDate) {
//             const interval = setInterval(() => {
//                 console.log("Polling latest OI data...");
//                 if (CallToken) {
//                     fetchDataByToken(CallToken, "CE");
//                 }
//                 if (PutToken) {
//                     fetchDataByToken(PutToken, "PE");
//                 }
//             }, REFRESH_INTERVAL);
//             return () => clearInterval(interval);
//         }
//     }, [date, CallToken, PutToken]);

//     const fetchDataByToken = async (token, type) => {
//         if (!token) return;
//         setLoading(true);
//         setErrorMessage(null);
//         try {
//             const formattedDate = moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD");
//             const response = await fetchOiAnalyz(authToken, formattedDate, token, type);
//             console.log(`API Response for ${type}:`, response);
//             if (response?.data?.length > 0) {
//                 const optionData = response.data[0];
//                 if (type === "PE") {
//                     setPutData(optionData.data);
//                     setPutSymbol(optionData.trading_symbol);
//                     setPutToken(optionData.token);
//                 } else {
//                     setCallData(optionData.data);
//                     setCallSymbol(optionData.trading_symbol);
//                     setCallToken(optionData.token);
//                 }
//             } else {
//                 setErrorMessage(`No ${type} data available for the selected option.`);
//             }
//         } catch (error) {
//             console.error(`Error fetching ${type} OI data:`, error);
//             setErrorMessage(`Failed to fetch ${type} data. Please try again later.`);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // --------------------- Automatic MACD Subscription --------------------- //
//     useEffect(() => {
//         const subscribeWhenReady = () => {
//             if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
//                 subscribeMacd(Number(token));
//             } else {
//                 setTimeout(subscribeWhenReady, 100);
//             }
//         };
//         if (isConnected && token) {
//             subscribeWhenReady();
//         }
//     }, [isConnected, token, wsRef]);

//     // --------------------- WebSocket Incoming Message Processing --------------------- //
//     useEffect(() => {
//         if (latestMessage) {
//             if (latestMessage.type === "history" && latestMessage.token && latestMessage.data) {
//                 console.log("Received historical data:", latestMessage.data.length, "candles");
//             } else if (latestMessage.type === "macd_histogram" && latestMessage.token && latestMessage.data) {
//                 console.log("Received MACD histogram data:", latestMessage.data);
//                 setMacdData((prev) => ({
//                     ...prev,
//                     [latestMessage.token]: latestMessage.data,
//                 }));
//             } else if (latestMessage.token && latestMessage.price && latestMessage.timestamp) {
//                 console.log("Received new tick:", latestMessage);
//                 const newTick = {
//                     time: latestMessage.timestamp,
//                     open: latestMessage.price,
//                     high: latestMessage.price,
//                     low: latestMessage.price,
//                     close: latestMessage.price,
//                 };
//                 setLiveData((prev) => {
//                     const updatedData = prev[latestMessage.token]
//                         ? [...prev[latestMessage.token], newTick]
//                         : [newTick];
//                     return { ...prev, [latestMessage.token]: updatedData };
//                 });
//             }
//         }
//     }, [latestMessage]);

//     // --------------------- Derived Data & Config --------------------- //
//     const history = historicalData[token] || [];
//     const tickData = liveData[token] || [];
//     const latestTick = tickData.length > 0 ? tickData[tickData.length - 1] : null;

//     // --------------------- Option Maps --------------------- //
//     useEffect(() => {
//         fetchOptionMapsByTradeDate(authToken, formattedDate)
//             .then((data) => {
//                 console.log("Fetched Option Map Data:", data.data);
//                 setPutMapData(data.peData);
//                 setCallMapData(data.ceData);
//             })
//             .catch((error) => console.error("API Error:", error));
//     }, [date, authToken]);

//     const getSeriesConfig = (stockData, symbol, oiThreshold) => {
//         if (!symbol) return {};
//         const formatData = (data, key) => {
//             return data
//                 .map(({ datetime, [key]: value, trend }) => {
//                     const timestampIST = new Date(datetime).getTime() / 1000;
//                     const timestampUTC = timestampIST + 19800;
//                     return {
//                         time: timestampUTC,
//                         value: parseFloat(value),
//                         trend: trend,
//                     };
//                 })
//                 .filter((item) => !isNaN(item.value));
//         };
//         const markers = stockData
//             .filter(({ trend, oi_perc_change }) => trend !== 0 && Math.abs(oi_perc_change) >= oiThreshold)
//             .map(({ trend, datetime }) => ({
//                 time: formatToUTCTimestamp(datetime),
//                 position: trend === 1 ? "belowBar" : "aboveBar",
//                 color: trend === 1 ? "#2196F3" : "#e91e63",
//                 shape: trend === 1 ? "arrowUp" : "arrowDown",
//                 text: `${trend === 1 ? "B" : "S"} @ ${formatValue(
//                     stockData.find((d) => formatToUTCTimestamp(d.datetime) === formatToUTCTimestamp(datetime))?.ltp
//                 )}`,
//             }));
//         return {
//             price: {
//                 type: "line",
//                 data: formatData(stockData, "ltp"),
//                 options: {
//                     color: "#1f77b4",
//                     lineWidth: 2,
//                     priceScaleId: "left",
//                     symbol: symbol,
//                 },
//                 markers: markers,
//             },
//             openInterest: {
//                 type: "line",
//                 data: formatData(stockData, "open_interest"),
//                 options: {
//                     color: "#ff7f0e",
//                     lineWidth: 2,
//                     priceScaleId: "right",
//                     symbol: symbol,
//                 },
//             },
//             oi_perc_change: {
//                 type: "histogram",
//                 data: stockData
//                     .map(({ datetime, oi_perc_change }) => ({
//                         time: new Date(datetime).getTime() / 1000 + 19800,
//                         value: parseFloat(oi_perc_change),
//                         color: oi_perc_change > 0 ? "green" : oi_perc_change < 0 ? "red" : "gray",
//                     }))
//                     .filter((item) => !isNaN(item.value)),
//                 options: {
//                     priceScaleId: "left",
//                     lineWidth: 1,
//                     symbol: symbol,
//                 },
//             },
//         };
//     };

//     const seriesConfig1 = useMemo(() => {
//         if (symbol1) {
//             return getSeriesConfig(stockData1, symbol1, PutoiThreshold);
//         }
//         return {};
//     }, [stockData1, symbol1]);

//     const seriesConfig2 = useMemo(() => {
//         if (symbol2) {
//             return getSeriesConfig(stockData2, symbol2, CalloiThreshold);
//         }
//         return {};
//     }, [stockData2, symbol2]);

//     const pivotPoints = [
//         { price: 300, label: "Pivot", color: "red" },
//         { price: 200, label: "Resistance 1", color: "green" },
//         { price: 100, label: "Support 1", color: "blue" },
//     ];

//     // --------------------- Render --------------------- //
//     return (
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <Container
//                 maxWidth={false}
//                 disableGutters
//                 sx={{ width: "100%", height: "100%", padding: 0.5, margin: 0 }}
//             >
//                 {/* Top Controls */}
//                 <Box
//                     sx={{
//                         display: "flex",
//                         flexDirection: "column",
//                         gap: 2,
//                         alignItems: "center",
//                         marginBottom: 2,
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             display: "flex",
//                             gap: 2,
//                             alignItems: "center",
//                             justifyContent: "center",
//                             width: "100%",
//                         }}
//                     >
//                         <Box sx={{ width: "10%" }}>
//                             <FormControl fullWidth>
//                                 <InputLabel id="pe-strike">Put Strike</InputLabel>
//                                 <Select
//                                     labelId="pe-strike-price"
//                                     value={JSON.stringify({ strike: selectedPutOption, token: PutToken })}
//                                     onChange={handlePutSelectChange}
//                                     label="Select Put Option"
//                                     MenuProps={{
//                                         PaperProps: { style: { maxHeight: 200, overflowY: "auto" } },
//                                     }}
//                                 >
//                                     {PutMapData &&
//                                         Object.entries(PutMapData).map(([strike, token]) => (
//                                             <MenuItem key={token} value={JSON.stringify({ strike, token })}>
//                                                 {strike}
//                                             </MenuItem>
//                                         ))}
//                                 </Select>
//                             </FormControl>
//                         </Box>

//                         <Box sx={{ flex: 1 }}>
//                             <DatePicker
//                                 label="Select Date"
//                                 value={date}
//                                 onChange={handleDateChange}
//                                 renderInput={(params) => <TextField {...params} fullWidth />}
//                             />
//                         </Box>

//                         {/* Expand/Collapse Icon Button */}
//                         <Box>
//                             <IconButton
//                                 onClick={() =>
//                                     setLayoutMode(layoutMode === "sideBySide" ? "stacked" : "sideBySide")
//                                 }
//                             >
//                                 {layoutMode === "sideBySide" ? <ExpandMoreIcon /> : <ExpandLessIcon />}
//                             </IconButton>
//                         </Box>

//                         <Box sx={{ width: "10%" }}>
//                             <FormControl fullWidth>
//                                 <InputLabel id="ce-strike">Call Strike</InputLabel>
//                                 <Select
//                                     labelId="ce-strike-price"
//                                     value={JSON.stringify({ strike: selectedCallOption, token: CallToken })}
//                                     onChange={handleCallSelectChange}
//                                     label="Select Call Option"
//                                     MenuProps={{
//                                         PaperProps: { style: { maxHeight: 200, overflowY: "auto" } },
//                                     }}
//                                 >
//                                     {CallMapData &&
//                                         Object.entries(CallMapData).map(([strike, token]) => (
//                                             <MenuItem key={token} value={JSON.stringify({ strike, token })}>
//                                                 {strike}
//                                             </MenuItem>
//                                         ))}
//                                 </Select>
//                             </FormControl>
//                         </Box>
//                     </Box>
//                 </Box>

//                 {errorMessage && (
//                     <Box sx={{ color: "red", textAlign: "center", marginBottom: 2 }}>
//                         {errorMessage}
//                     </Box>
//                 )}

//                 <Box sx={{ position: "relative" }}>
//                     {loading && (
//                         <Box
//                             sx={{
//                                 position: "absolute",
//                                 top: 10,
//                                 right: 10,
//                                 zIndex: 1000,
//                             }}
//                         >
//                             <CircularProgress size={24} />
//                         </Box>
//                     )}
//                 </Box>


//                 <Grid container spacing={2} padding={2} sx={{ mb: 4 }}>
//                     <Grid item xs={12} md={layoutMode === "sideBySide" ? 6 : 12}>
//                         <Box sx={{ width: "100%", height: "350px" }}>
//                             {symbol1 && stockData1.length > 0 && (
//                                 <Box sx={{ width: "100%", height: "100%" }}>
//                                     <MultiLineOIChart
//                                         seriesConfig={seriesConfig1}
//                                         pivotPoints={pivotPoints}
//                                         width="100%"
//                                         height={400}
//                                         symbol={symbol1}
//                                     />
//                                 </Box>
//                             )}
//                         </Box>
//                     </Grid>
//                     <Grid item xs={12} md={layoutMode === "sideBySide" ? 6 : 12}>
//                         <Box sx={{ width: "100%", height: "350px" }}>
//                             {symbol2 && stockData2.length > 0 && (
//                                 <Box sx={{ width: "100%", height: "100%" }}>
//                                     <MultiLineOIChart
//                                         seriesConfig={seriesConfig2}
//                                         pivotPoints={pivotPoints}
//                                         width="100%"
//                                         height={400}
//                                         symbol={symbol2}
//                                     />
//                                 </Box>
//                             )}
//                         </Box>
//                     </Grid>
//                 </Grid>

//                 {/* Render MACD Charts */}
//                 <Box sx={{ marginTop: "20px" }}>
//                     {macdData[token] ? (
//                         <DynamicMACDCharts macdData={macdData[token]} />
//                     ) : (
//                         <p>No MACD data available.</p>
//                     )}
//                 </Box>
//             </Container>
//         </LocalizationProvider>
//     );
// };

// export default TraderPageTV;


