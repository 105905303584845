import React from 'react';
import { Box, Typography } from '@mui/material';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    LabelList,
    Label,
} from 'recharts';

// Custom label renderer that draws a small rounded circle at a fixed vertical position.
const renderRoundedLabel = (props) => {
    const { x, width, value } = props; // 'value' is the candle color (11 or 22)
    const circleRadius = 5;
    let fill = "gray";
    if (value === 11) {
        fill = "green";
    } else if (value === 22) {
        fill = "red";
    }
    // Center the circle horizontally on the bar.
    const cx = x + (width ? width / 2 : 0);
    // Use a fixed y position (e.g. 20px from the top plus the circle radius).
    const cy = 20 + circleRadius;
    return <circle cx={cx} cy={cy} r={circleRadius} fill={fill} />;
};

const DynamicMACDCharts = ({ macdData }) => {
    if (!macdData || Object.keys(macdData).length === 0) {
        return <Typography>Loading MACD data...</Typography>;
    }

    // Count the number of charts to determine grid columns.
    const numCharts = Object.keys(macdData).length;

    return (
        // Resizable container with a border and padding.
        <Box
            sx={{
                border: '1px solid #ccc',
                resize: 'both',
                overflow: 'auto',
                p: 2,
                minWidth: 300,
                minHeight: 120,
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${numCharts < 4 ? numCharts : 4}, 1fr)`,
                    gap: 2,
                }}
            >
                {Object.entries(macdData).map(([interval, data]) => {
                    // Add an "index" field for X-axis labels.
                    const chartData = data.map((d, index) => ({
                        ...d,
                        index: index + 1,
                    }));

                    return (
                        <Box key={interval} sx={{ minWidth: 100 }}>
                            <Box sx={{ width: '100%', height: 80 }}>
                                <ResponsiveContainer width="100%" height={100}>
                                    <BarChart data={chartData}>
                                        <XAxis dataKey="index" tick={false} axisLine={false}>
                                            <Label
                                                value={interval}
                                                position="insideBottom"
                                                offset={0}
                                                style={{
                                                    textAnchor: 'middle',
                                                    fontSize: 10,
                                                    fill: '#555',
                                                    fontWeight: 'bold',
                                                }}
                                            />
                                        </XAxis>

                                        <YAxis tick={{ fontSize: 10 }} tickLine={false} axisLine={false} />
                                        <Tooltip />
                                        <ReferenceLine y={0} stroke="#000" />
                                        <Bar dataKey="macd_histogram" strokeWidth={1}>
                                            {chartData.map((entry, index) => {
                                                const isPositive = entry.macd_histogram >= 0;
                                                const baseColor = isPositive ? "#00C851" : "#ff4444";
                                                const lowIntensityColor = isPositive
                                                    ? "rgba(0,200,81,0.5)"
                                                    : "rgba(255,68,68,0.5)";
                                                let fillColor = baseColor;
                                                if (
                                                    index > 0 &&
                                                    Math.abs(entry.macd_histogram) <
                                                    Math.abs(chartData[index - 1].macd_histogram)
                                                ) {
                                                    fillColor = lowIntensityColor;
                                                }
                                                return <Cell key={`cell-${index}`} fill={fillColor} />;
                                            })}
                                            <LabelList
                                                dataKey="heikinashi_candle_color" // This passes the candle color value (11 or 22)
                                                position="top"
                                                content={renderRoundedLabel}
                                            />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default DynamicMACDCharts;




// import React from 'react';
// import { Box, Typography } from '@mui/material';
// import {
//   BarChart,
//   Bar,
//   Cell,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
//   ReferenceLine,
// } from 'recharts';

// const DynamicMACDCharts = ({ macdData }) => {
//   if (!macdData || Object.keys(macdData).length === 0) {
//     return <Typography>Loading MACD data...</Typography>;
//   }

//   return (
//     <Box sx={{ p: 2 }}>
//       {/* Display all charts in a single row with horizontal scrolling if needed */}
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'row',
//           gap: 4,
//           overflowX: 'auto',
//         }}
//       >
//         {Object.entries(macdData).map(([interval, data]) => {
//           // Add an "index" field for the X-axis labels.
//           const chartData = data.map((d, index) => ({
//             ...d,
//             index: index + 1,
//           }));

//           return (
//             <Box key={interval} sx={{ minWidth: 400 }}>
//               <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
//                 {interval} Chart
//               </Typography>
//               <Box sx={{ width: '100%', height: 300 }}>
//                 <ResponsiveContainer width="100%" height="100%">
//                   <BarChart data={chartData}>
//                     <XAxis dataKey="index" />
//                     <YAxis />
//                     <Tooltip />
//                     {/* Draw a reference line at 0 */}
//                     <ReferenceLine y={0} stroke="#000" />
//                     <Bar dataKey="macd_histogram" strokeWidth={1}>
//                       {chartData.map((entry, index) => {
//                         const isPositive = entry.macd_histogram >= 0;
//                         const baseColor = isPositive ? "#00C851" : "#ff4444";
//                         // Use lower opacity if the current bar is less intense than the previous bar.
//                         const lowIntensityColor = isPositive
//                           ? "rgba(0,200,81,0.5)"
//                           : "rgba(255,68,68,0.5)";
//                         let fillColor = baseColor;
//                         if (
//                           index > 0 &&
//                           Math.abs(entry.macd_histogram) <
//                             Math.abs(chartData[index - 1].macd_histogram)
//                         ) {
//                           fillColor = lowIntensityColor;
//                         }
//                         return <Cell key={`cell-${index}`} fill={fillColor} />;
//                       })}
//                     </Bar>
//                   </BarChart>
//                 </ResponsiveContainer>
//               </Box>
//             </Box>
//           );
//         })}
//       </Box>
//     </Box>
//   );
// };

// export default DynamicMACDCharts;
