
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Chart, CandlestickSeries } from 'lightweight-charts-react-wrapper';
import { CrosshairMode } from 'lightweight-charts';
import { connectWebSocket } from '../../utils/websocket';

const MinuteChart = () => {
  const seriesRef = useRef(null); // Ref to access the series API
  const dataRef = useRef([]); // Ref to store candlestick data without causing re-renders
  const currentBarRef = useRef(null); // Ref to track the current candlestick bar
  const [data, setData] = useState([]); // State for candlestick data
  const token = 26000; // Token to identify the financial instrument
  const [isConnected, setIsConnected] = useState(false); // WebSocket connection status
  const [latestMessage, setLatestMessage] = useState(null); // Store the latest WebSocket message
  const uri = `ws://localhost:8766`; // WebSocket server URI

  // Calculate 9:15 AM IST timestamp for the current day
  const calculate915AMISTTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // Month is 0-based
    const day = now.getDate();

    // Create a Date object for 9:15 AM IST
    const nineFifteenIST = new Date(Date.UTC(year, month, day, 3, 45)); // 3:45 UTC is 9:15 IST
    return Math.floor(nineFifteenIST.getTime() / 1000); // Convert to Unix timestamp
  };

    useEffect(() => {
        const fetchDataWithTimeout = async (url, timeout = 20000) => {
          const controller = new AbortController();
          const id = setTimeout(() => controller.abort(), timeout);
    
          try {
            const response = await fetch(url, {
              signal: controller.signal,
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const initialData = await response.json();
            const formattedData = initialData.map((bar) => ({
              time: Math.floor(new Date(bar.timestamp).getTime() / 1000), // Convert time to UNIX timestamp in seconds
              open: bar.open,
              high: bar.high,
              low: bar.low,
              close: bar.close,
            }));
    
            console.log('Formatted Initial Data:', formattedData);
    
            // Sort the initial data by time in ascending order
            const sortedData = formattedData.sort((a, b) => a.time - b.time);
            dataRef.current = sortedData; // Store in ref for further updates
            setData(sortedData);
    
            // Initialize current bar with the latest bar
            if (sortedData.length > 0) {
              const lastBar = sortedData[sortedData.length - 1];
              currentBarRef.current = { ...lastBar };
            }
          } catch (error) {
            if (error.name === 'AbortError') {
              console.error('Fetch request was aborted due to timeout');
            } else {
              console.error('Failed to fetch initial data:', error);
            }
          } finally {
            clearTimeout(id);
          }
        };
    
        const initialTimestamp = calculate915AMISTTimestamp();
        const apiUrl = `https://localhost:8000/candles-data/?timestamp=${initialTimestamp}&token=${token}&time_frame=5min`;
    
        fetchDataWithTimeout(apiUrl, 20000); // Call with a 20-second timeout
    
      }, []);

  // Handle WebSocket message: convert tick data into candlestick updates
  const handleWebSocketMessage = useCallback(
    (tickData) => {
      try {
        console.log("tickDAta",tickData)
        if (tickData && tickData.price !== undefined && tickData.timestamp !== undefined) {
          // Convert tick timestamp to a Unix timestamp in seconds
          const tickTime = Math.floor(tickData.timestamp);

          // Calculate the start time of the current minute
          const startTimeOfCurrentMinute = Math.floor(tickTime / 60) * 60;

          // Initialize a new bar if it's the first tick or a new minute has started
          if (
            !currentBarRef.current ||
            !currentBarRef.current.time ||
            currentBarRef.current.time !== startTimeOfCurrentMinute
          ) {
            // If there's an existing bar, finalize it before starting a new one
            if (currentBarRef.current && currentBarRef.current.time) {
              dataRef.current.push(currentBarRef.current);
              if (seriesRef.current) {
                seriesRef.current.update(currentBarRef.current);
              }
            }

            // Start a new bar
            currentBarRef.current = {
              time: startTimeOfCurrentMinute,
              open: tickData.price,
              high: tickData.price,
              low: tickData.price,
              close: tickData.price,
            };

            // Set the data state with a new bar to trigger re-render
            setData([...dataRef.current, currentBarRef.current]);
          } else {
            // Update the existing bar
            currentBarRef.current.high = Math.max(currentBarRef.current.high, tickData.price);
            currentBarRef.current.low = Math.min(currentBarRef.current.low, tickData.price);
            currentBarRef.current.close = tickData.price;

            // Update the chart with the latest tick data
            if (seriesRef.current) {
              seriesRef.current.update(currentBarRef.current);
            }
          }
        } else {
          console.error('Invalid tick data format:', tickData);
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    },
    []
  );
  useEffect(() => {
    const wsRef = connectWebSocket(
      uri,
      setIsConnected,
      setLatestMessage,
      () => {
        // Send subscription message once connected
        const message = { subscribe: token };
        wsRef.current.send(JSON.stringify(message));
        console.log('Subscribed to WebSocket with message:', JSON.stringify(message));
      }
    );

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [uri, token]); // Recreate WebSocket connection if uri or token changes

  useEffect(() => {
    if (latestMessage) {
      handleWebSocketMessage(latestMessage);
    }
  }, [latestMessage, handleWebSocketMessage]); // Update on latest message only

  return (
    <>
      <h1>Realtime Candlestick Chart</h1>
      <Chart
        width={800}
        height={400}
        crosshair={{ mode: CrosshairMode.Normal }}
        timeScale={{
          timeVisible: true,
          secondsVisible: true,
          tickMarkFormatter: (time) => {
            const date = new Date(time * 1000);
            return `${date.getHours().toString().padStart(2, '0')}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
          },
        }}
      >
        <CandlestickSeries
          data={data}
          ref={seriesRef}
          options={{ upColor: 'rgba(0, 150, 136, 0.6)', borderVisible: true }}
        />
      </Chart>
    </>
  );
};

export default MinuteChart;











// import React, { useEffect, useRef, useState, useCallback } from 'react';
// import { Chart, CandlestickSeries } from 'lightweight-charts-react-wrapper';
// import { CrosshairMode } from 'lightweight-charts';
// import { connectWebSocket } from '../../utils/websocket';

// const MinuteChart = () => {
//   const seriesRef = useRef(null); // Ref to access the series API
//   const dataRef = useRef([]); // Ref to store candlestick data without causing re-renders
//   const currentBarRef = useRef(null); // Ref to track the current candlestick bar
//   const [data, setData] = useState([]); // State for candlestick data
//   const token = 26000; // Token to identify the financial instrument
//   const [isConnected, setIsConnected] = useState(false); // WebSocket connection status
//   const [latestMessage, setLatestMessage] = useState(null); // Store the latest WebSocket message
//   const uri = `wss://localhost:8765`; // WebSocket server URI

//   // Calculate 9:15 AM IST timestamp for the current day
//   const calculate915AMISTTimestamp = () => {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = now.getMonth(); // Month is 0-based
//     const day = now.getDate();

//     // Create a Date object for 9:15 AM IST
//     const nineFifteenIST = new Date(Date.UTC(year, month, day, 3, 45)); // 3:45 UTC is 9:15 IST
//     return Math.floor(nineFifteenIST.getTime() / 1000); // Convert to Unix timestamp
//   };

//   // Fetch initial candlestick data from API
//   useEffect(() => {
//     const initialTimestamp = calculate915AMISTTimestamp();
//     const apiUrl = `https://localhost:8000/candles-data/?timestamp=${initialTimestamp}&token=${token}&time_frame=5min`;

//     // Fetch initial data
//     fetch(apiUrl)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((initialData) => {
//         const formattedData = initialData.map((bar) => ({
//           time: Math.floor(new Date(bar.timestamp).getTime() / 1000), // Convert time to UNIX timestamp in seconds
//           open: bar.open,
//           high: bar.high,
//           low: bar.low,
//           close: bar.close,
//         }));

//         console.log('Formatted Initial Data:', formattedData);

//         // Sort the initial data by time in ascending order
//         const sortedData = formattedData.sort((a, b) => a.time - b.time);
//         dataRef.current = sortedData; 
//         console.log("SOrted data",sortedData)
//         setData(sortedData);

//         // Initialize current bar with the latest bar
//         if (sortedData.length > 0) {
//           const lastBar = sortedData[sortedData.length - 1];
//           currentBarRef.current = { ...lastBar };
//         }
//       })
//       .catch((error) => {
//         console.error('Failed to fetch initial data:', error);
//       });
//   }, []); // Empty dependency array to run once on mount

//   // Handle WebSocket message: convert tick data into candlestick updates
//   const handleWebSocketMessage = useCallback(
//     (tickData) => {
//       try {
//         // Check if the parsed data has required fields
//         if (tickData && tickData.price !== undefined && tickData.timestamp !== undefined) {
//           // Convert tick timestamp to a Unix timestamp in seconds
//           const tickTime = Math.floor(tickData.timestamp);

//           // If no current bar, start a new one
//           if (!currentBarRef.current || !currentBarRef.current.time) {
//             currentBarRef.current = {
//               open: tickData.price,
//               high: tickData.price,
//               low: tickData.price,
//               close: tickData.price,
//               time: Math.floor(tickTime / 60) * 60, // Align to minute start
//             };
//             return;
//           }

//           // Check if we need to start a new candlestick bar
//           if (tickTime >= currentBarRef.current.time + 60) {
//             // Complete the current bar
//             if (currentBarRef.current.time) {
//               const lastBar = dataRef.current[dataRef.current.length - 1];
//               if (!lastBar || currentBarRef.current.time !== lastBar.time) {
//                 // Update dataRef without triggering re-render
//                 dataRef.current = [...dataRef.current, currentBarRef.current];

//                 // Update chart using lightweight-charts 'update' method
//                 if (seriesRef.current) {
//                   seriesRef.current.update(currentBarRef.current);
//                 }
//               }
//             }

//             // Start a new bar
//             currentBarRef.current = {
//               open: tickData.price,
//               high: tickData.price,
//               low: tickData.price,
//               close: tickData.price,
//               time: Math.floor(tickTime / 60) * 60, // Align time to the start of the minute
//             };
//           } else {
//             // Update the current bar with the tick data
//             currentBarRef.current = {
//               ...currentBarRef.current,
//               high: Math.max(currentBarRef.current.high, tickData.price),
//               low: Math.min(currentBarRef.current.low, tickData.price),
//               close: tickData.price,
//             };

//             // Optimistically update the last data point
//             if (seriesRef.current && dataRef.current.length > 0) {
//               // Update only the last bar if it's the same time
//               const lastIndex = dataRef.current.length - 1;
//               if (dataRef.current[lastIndex].time === currentBarRef.current.time) {
//                 seriesRef.current.update(currentBarRef.current);
//                 dataRef.current[lastIndex] = { ...currentBarRef.current };
//               }
//             }
//           }
//         } else {
//           console.error('Invalid tick data format:', tickData);
//         }
//       } catch (error) {
//         console.error('Failed to parse WebSocket message:', error);
//       }
//     },
//     [] // Ensure this function doesn't change unnecessarily
//   );

//   // Establish WebSocket connection using the utility function
//   useEffect(() => {
//     const wsRef = connectWebSocket(
//       uri,
//       setIsConnected,
//       setLatestMessage,
//       () => {
//         // Send subscription message once connected
//         const message = { subscribe: token };
//         wsRef.current.send(JSON.stringify(message));
//         console.log('Subscribed to WebSocket with message:', JSON.stringify(message));
//       }
//     );

//     return () => {
//       if (wsRef.current) {
//         wsRef.current.close();
//       }
//     };
//   }, [uri, token]); // Recreate WebSocket connection if uri or token changes

//   useEffect(() => {
//     if (latestMessage) {
//       handleWebSocketMessage(latestMessage);
//     }
//   }, [latestMessage, handleWebSocketMessage]); // Update on latest message only

//   return (
//     <>
//       <h1>Realtime Candlestick Chart</h1>
//       <Chart
//         width={800}
//         height={400}
//         crosshair={{ mode: CrosshairMode.Normal }}
//         timeScale={{
//           timeVisible: true,
//           secondsVisible: true,
//           tickMarkFormatter: (time) => {
//             const date = new Date(time * 1000);
//             return `${date.getHours().toString().padStart(2, '0')}:${date
//               .getMinutes()
//               .toString()
//               .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
//           },
//         }}
//       >
//         <CandlestickSeries
//           data={data}
//           ref={seriesRef}
//           options={{ upColor: 'rgba(0, 150, 136, 0.6)', borderVisible: true }}
//         />
//       </Chart>
//     </>
//   );
// };

// export default MinuteChart;


