import { useState, useEffect, useRef } from 'react';

export const useWebSocket = (uri, retryCount = 0) => {
  const [isConnected, setIsConnected] = useState(false);
  const [latestMessage, setLatestMessage] = useState(null);
  const wsRef = useRef(null);

  const connectWebSocket = () => {
    try {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        console.log('WebSocket already connected.');
        return;
      }

      wsRef.current = new WebSocket(uri);

      wsRef.current.onopen = () => {
        console.log('WebSocket Connected');
        setIsConnected(true);
      };

      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setLatestMessage(data);
      };

      wsRef.current.onclose = () => {
        console.log('WebSocket Closed');
        setIsConnected(false);
        setTimeout(() => connectWebSocket(), Math.min(3000 * (retryCount + 1), 30000));
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
        if (wsRef.current) wsRef.current.close();
      };
    } catch (error) {
      console.error('Failed to connect WebSocket:', error);
    }
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (wsRef.current) {
        console.log('Cleaning up WebSocket on unmount.');
        wsRef.current.close();
      }
    };
  }, [uri, retryCount]);

  return { isConnected, latestMessage, wsRef };
};
