import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, TimeScale);

const LineChart = ({
  data,
  datasetsConfig = [], 
  title = 'Dynamic Chart',
  xTitle = 'X-Axis',
  yTitle = 'Y-Axis',
}) => {
  const chartRef = useRef(null);
  const processData = (data, xKey, yKey) =>
    data?.map((item) => ({
      x: new Date(item[xKey]), 
      y: item[yKey], 
    }));
const getBarColor = (value) => {
  if (value > 0) {
    return 'rgba(75, 192, 192, 1)'; // Green color for positive change
  }
  return 'rgba(255, 99, 132, 1)'; // Red color for negative change
};


  const chartData = {
    datasets: [
      ...datasetsConfig.map((config) => {
        const dataset = {
          label: config.name,
          data: processData(data, config.xKey, config.yKey),
          borderColor: config.color || 'rgba(75, 192, 192, 1)',
          backgroundColor: config.fillColor || 'rgba(75, 192, 192, 0.2)',
          fill: false,
          pointRadius: 0,
          borderWidth: 2.5,
          tension: 0.2,
          yAxisID: config.yAxisID || 'y',
          type: config.chartType || 'line',
          order: config.chartType === 'bar' ? 1 : 2, // Bars render behind the line chart
        };
  
        if (config.chartType === 'bar') {
          dataset.backgroundColor = dataset.data.map((d) => getBarColor(d.y));
          dataset.barThickness = 8;
          dataset.categoryPercentage = 0.8;
          dataset.yAxisID = 'y2';
          dataset.order = 1;
        }
  
        return dataset;
      }),
  
      // 🔹 Bar Chart for `trend` (Progress Bar Style)
      {
        label: "Trend",
        data: data?.map((item) => ({
          x: new Date(item.datetime),
          y: item.trend === 1 ? 1 : item.trend === -1 ? -1 : 0, // Keep 0 for no trend
        })),
        backgroundColor: data?.map((item) =>
          item.trend === 1 ? "rgba(0, 200, 0, 0.6)" : "rgba(255, 0, 0, 0.6)"
        ), // Semi-transparent bars
        borderColor: data?.map((item) =>
          item.trend === 1 ? "rgba(0, 200, 0, 1)" : "rgba(255, 0, 0, 1)"
        ),
        barThickness: 4, // Keep it thin like a progress bar
        categoryPercentage: 1, // Make it full width
        type: "bar",
        yAxisID: "y3", // Separate Y-axis for trend
        order: 0, // Trend bars at the back
      },
  
      // 🔹 Bar Chart for `oi_perc_change`
      {
        label: "Open Interest Percentage Change",
        data: processData(data, "datetime", "oi_perc_change"),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        type: "bar",
        yAxisID: "y2",
        barThickness: 8,
        categoryPercentage: 0.8,
        order: 1,
      },
    ],
  };
  
  

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.y}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        title: {
          display: true,
          text: xTitle,
        },
        time: {
          tooltipFormat: "yyyy-MM-dd HH:mm",
        },
      },
      y: {
        id: "y",
        title: {
          display: true,
          text: yTitle,
        },
        position: "left",
      },
      y2: {
        id: "y2",
        title: {
          display: true,
          text: "OI Percentage Change",
        },
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return value.toFixed(2) + "%";
          },
        },
      },
      y3: {
        id: "y3",
        title: {
          display: true,
          text: "Trend (1 = Green, -1 = Red)",
        },
        position: "right",
        ticks: {
          stepSize: 1,
          min: -1,
          max: 1,
          callback: function (value) {
            return value === 1 ? "↑ (Green)" : value === -1 ? "↓ (Red)" : "";
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
  

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data, datasetsConfig]);

  return <Line ref={chartRef} data={chartData} options={options} />;
};

export default LineChart;



// import React, { useEffect, useRef } from 'react';
// import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
// import { Chart } from 'react-chartjs-2';
// import 'chartjs-adapter-date-fns';

// ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, TimeScale);

// const LineChart = ({
//   data,
//   datasetsConfig = [],
//   title = 'Dynamic Chart',
//   xTitle = 'X-Axis',
//   yTitle = 'Y-Axis',
// }) => {
//   const chartRef = useRef(null);

//   const processData = (data, xKey, yKey) =>
//     data?.map((item) => ({
//       x: new Date(item[xKey]),
//       y: item[yKey],
//     }));

//   // Function to determine bar color based on value
//   const getBarColor = (value) => (value > 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)');

//   const chartData = {
//     datasets: datasetsConfig?.map((config) => {
//       const dataset = {
//         label: config.name,
//         data: processData(data, config.xKey, config.yKey),
//         borderColor: config.color || 'rgba(75, 192, 192, 1)',
//         backgroundColor: config.fillColor || 'rgba(75, 192, 192, 0.2)',
//         fill: false,
//         pointRadius: 0,
//         borderWidth: 2.5,
//         tension: 0.2,
//         yAxisID: config.yAxisID || 'y',
//         type: config.chartType || (config.yKey === 'oi_perc_change' ? 'bar' : 'line'),
//       };

//       if (config.chartType === 'bar' || config.yKey === 'oi_perc_change') {
//         dataset.backgroundColor = dataset.data.map(d => getBarColor(d.y));
//       }

//       return dataset;
//     }),
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       title: {
//         display: true,
//         text: title,
//       },
//       legend: {
//         position: "top",
//         labels: {
//           usePointStyle: true,
//         },
//       },
//       tooltip: {
//         mode: "index",
//         intersect: false,
//         callbacks: {
//           label: function (context) {
//             return `${context.dataset.label}: ${context.raw.y}`;
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         type: 'time',
//         title: {
//           display: true,
//           text: xTitle,
//         },
//         time: {
//           tooltipFormat: 'yyyy-MM-dd HH:mm',
//         },
//       },
//       y: {
//         id: 'y',
//         title: {
//           display: true,
//           text: yTitle,
//         },
//         position: 'left',
//       },
//       y2: {
//         id: 'y2',
//         title: {
//           display: true,
//           text: 'OI Percentage Change',
//         },
//         position: 'right',
//         grid: {
//           drawOnChartArea: false,
//         },
//         ticks: {
//           beginAtZero: true,
//           callback: function (value) {
//             return value.toFixed(2) + '%';
//           },
//         },
//       },
//     },
//   };

//   useEffect(() => {
//     if (chartRef.current) {
//       chartRef.current.update();
//     }
//   }, [data, datasetsConfig]);

//   return <Chart ref={chartRef} type="bar" data={chartData} options={options} />;
// };

// export default LineChart;
