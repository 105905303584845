import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Paper, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import LineChart from "../../components/Chart/MarketPro/OiChart";
import { fetchMarketDayData ,fetchOiAnalyz} from '../../utils/services';
import { getToken } from '../../utils/helpers';

const TraderPage = () => {
  const [date, setDate] = useState(new Date()); // Default to today's date
  const [stockData, setStockData] = useState([]);
  const [stockData2, setStockData2] = useState([]);
  const [symbol, setSymbol] = useState('');
  const [symbol2, setSymbol2] = useState('');
  const authToken = getToken();

  const datasetsConfig = [
    {
      name: 'Price (LTP)',
      xKey: 'datetime',
      yKey: 'ltp',
      color: 'rgba(75, 192, 192, 1)',
      yAxisID: 'y',
      chartType: 'line',
    },
    {
      name: 'Open Interest',
      xKey: 'datetime',
      yKey: 'open_interest',
      color: 'rgba(153, 102, 255, 1)',
      yAxisID: 'y2',
      chartType: 'line',
    },
    // {
    //   name: 'Open Interest Percentage Change',
    //   xKey: 'datetime',
    //   yKey: 'oi_perc_change',
    //   chartType: 'bar', 
    //   fillColor: 'rgba(255, 99, 132, 0.5)',
    //   borderColor: 'rgba(255, 99, 132, 1)',
    // },
  ];

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const formattedDate = date.toISOString().split('T')[0];
  //     const response = await fetchMarketDayData(authToken, formattedDate);
  //     console.log("response",response);
  //     if (response?.data?.length >= 2) {
  //       console.log("response.data.",response.data);
  //       setStockData(response.data[0].data);
  //       setStockData2(response.data[1].data);
  //       setSymbol(response.data[0].trading_symbol);
  //       setSymbol2(response.data[1].trading_symbol);
  //     }
  //   };

  //   fetchData();
  // }, [date, authToken]);

  
  useEffect(() => {
    const fetchData = async () => {
      const formattedDate = date.toISOString().split('T')[0];
      const response = await   fetchOiAnalyz(authToken, formattedDate);
      console.log("response",response);
      if (response?.data?.length >= 2) {
        console.log("response.data.",response.data);
        setStockData(response.data[0].data);
        setStockData2(response.data[1].data);
        setSymbol(response.data[0].trading_symbol);
        setSymbol2(response.data[1].trading_symbol);
      }
    };

    fetchData();
  }, [date, authToken]);


  useEffect(() => {
    console.log('stockData2',stockData2);
    console.log('stockData',stockData);
    console.log('symbol1',symbol);
    console.log('symbol2',symbol2);

  }, [stockData2,stockData,symbol,symbol2]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          width: '100%',
          height: '100%',
          padding: 0.5,
          margin: 0,
        }}
      >
        <Box sx={{ margin: 2, display: 'flex', justifyContent: 'center' }}>
          <DatePicker
            label="Select Date"
            value={date}
            onChange={(newDate) => setDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Grid container spacing={0.5}>
          <Grid item xs={12} md={6} xl={12}>
            <Paper
              sx={{
                width: '100%',
                height: '100%',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '350px',
                }}
              >
                <LineChart
                  data={stockData}
                  datasetsConfig={datasetsConfig}
                  title={symbol}
                  xTitle="Time"
                  yTitle="Values"
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} xl={12}>
            <Paper
              sx={{
                width: '100%',
                height: '100%',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '350px',
                }}
              >
                <LineChart
                  data={stockData2}
                  datasetsConfig={datasetsConfig}
                  title={symbol2}
                  xTitle="Time"
                  yTitle="Values"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider> 
  );
};

export default TraderPage;



// import React, { useState, useEffect, useRef } from 'react';
// // import { Container } from "@mui/system";
// import { Container, Grid, Box, Card, Paper } from '@mui/material';

// import HomeComponent from "../HomePage/HomePage";
// import LineChart from "../../components/Chart/MarketPro/OiChart";
// import { fetchMarketDayData } from '../../utils/services';
// // import { useTheme } from "@mui/material";
// import { getToken } from '../../utils/helpers';

// const authToken = getToken();
// const date = '2024-12-05';
// const tokens = [48100];
// const tokens2 = [48101];
// const response = await fetchMarketDayData(authToken, date);

// console.log(response?.data?.data[0]?.data);
// const stockData = response?.data?.data[0]?.data;
// const stockData2 = response?.data?.data[1]?.data;
// const symbol =  response?.data?.data[0]?.trading_symbol;
// const symbol2 =  response?.data?.data[1]?.trading_symbol;
// // const resp = await fetchMarketDayData(authToken, date, [26000]);
// // const stockData3 = resp?.data?.data[0]?.data;

// const datasetsConfig = [
//     {
//         name: 'Price (LTP)',
//         xKey: 'datetime',
//         yKey: 'ltp',
//         color: 'rgba(75, 192, 192, 1)',
//         yAxisID: 'y',
//         chartType: 'line', // Line chart for price
//     },
//     {
//         name: 'Open Interest',
//         xKey: 'datetime',
//         yKey: 'open_interest',
//         color: 'rgba(153, 102, 255, 1)',
//         yAxisID: 'y2',
//         chartType: 'line', // Bar chart for open interest
//     },
//     // {
//     //     name: 'Epoch',
//     //     xKey: 'datetime',
//     //     yKey: 'epoch',
//     //     color: 'rgba(255, 99, 132, 1)',
//     //     yAxisID: 'y2',
//     //     chartType: 'line', // Line chart for epoch
//     // },
// ];



// const TraderPage = () => {
//     const [data, setData] = useState(stockData);
//     const chartRef = useRef(null);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             const newData = {
//                 datetime: new Date().toISOString(),
//                 ltp: Math.random() * 100,
//             };

//             setData((prevData) => {
//                 const updatedData = [...prevData, newData];
//                 if (updatedData.length > 100) {
//                     updatedData.shift();
//                 }
//                 return updatedData;
//             });
//         }, 1000);

//         return () => clearInterval(interval);
//     }, []);
//     return (
//         <Container
//             maxWidth={false}
//             disableGutters
//             sx={{
//                 width: '100%',
//                 height: '100%',
//                 padding: 0.5,
//                 margin: 0,
//             }}
//         >
//             <Grid container spacing={0.5}>
//                 {/* First Chart */}
//                 <Grid item xs={12} md={6}>
//                     <Paper
//                         sx={{
//                             width: '100%',
//                             height: '100%', // Ensures Paper fills its grid cell
//                             padding: 2,
//                             display: 'flex', // Aligns content inside
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '350px', // Consistent height for the chart
//                             }}
//                         >
//                             <LineChart
//                                 data={stockData}
//                                 datasetsConfig={datasetsConfig}
//                                 title={symbol}
//                                 xTitle="Time"
//                                 yTitle="Values"
//                             />
//                         </Box>
//                     </Paper>
//                 </Grid>

//                 {/* Second Chart */}
//                 <Grid item xs={12} md={6}>
//                     <Paper
//                         sx={{
//                             width: '100%',
//                             height: '100%', // Ensures Paper fills its grid cell
//                             padding: 2,
//                             display: 'flex', // Aligns content inside
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '350px', // Consistent height for the chart
//                             }}
//                         >
//                             <LineChart
//                                 data={stockData2}
//                                 datasetsConfig={datasetsConfig}
//                                 title={symbol2}
//                                 xTitle="Time"
//                                 yTitle="Values"
//                             />
//                         </Box>
//                     </Paper>
//                 </Grid>
//                 <Paper
//                         sx={{
//                             width: '100%',
//                             height: '100%', // Ensures Paper fills its grid cell
//                             padding: 2,
//                             display: 'flex', // Aligns content inside
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                         }}
//                     >
//                         <Box
//                             sx={{
//                                 width: '100%',
//                                 height: '350px', // Consistent height for the chart
//                             }}
//                         >
//                             <LineChart
//                                 // data={stockData3}
//                                 datasetsConfig={datasetsConfig}
//                                 title={symbol2}
//                                 xTitle="Time"
//                                 yTitle="Values"
//                             />
//                         </Box>
//                     </Paper>
//             </Grid>
//         </Container>
//     );

// };

// export default TraderPage;